import { World } from './js/World'
import "./style.scss"

window.createImageBitmap = undefined

const support = document.querySelector('div#support')
const start = document.querySelector('span#start');
const fixedWrapper = document.querySelector('.fixed-wrapper');

const filesContextImage = require.context('../static/img', false, /\.(jpg)$/);
const importedImage = {};
filesContextImage.keys().forEach((key) => {
    const fileName = key.replace('./', '').replace('.jpg', '');
    importedImage[fileName] = filesContextImage(key);
});

var mDragging = false;
var mDown = false;

let interval;

if (window.matchMedia("(min-width: 940px)").matches && window.matchMedia("(min-height: 700px)")) {
    support.classList.remove('visible');
    const canvas = document.querySelector('canvas.webgl')
    const world = new World(canvas)

    const zoneInfo = document.querySelector('div.zoneInfo');
    const title = zoneInfo.querySelector('div.header span');
    const description = zoneInfo.querySelector('div.description');
    const wrapperDescription = zoneInfo.querySelector('div.wrapper-description');
    const button = zoneInfo.querySelector('button#visit');
    
    const returnButton = zoneInfo.querySelector('button#return');
    const infoButton = zoneInfo.querySelector('button#info360');

    const close = zoneInfo.querySelector('#close'); 
    const closeDesc = zoneInfo.querySelector('#closeDesc'); 

    const unit = zoneInfo.querySelector('#unit');
    const nav = zoneInfo.querySelector('nav');
    const legend = zoneInfo.querySelector('div.legend');

    const groupButton = zoneInfo.querySelector('div.group-button');
    const prev = document.querySelector('button#prev');
    const next = document.querySelector('button#next');

    const lightbox = document.querySelector('#lightbox');
    const lightboxClose = lightbox.querySelector('.close-btn');
    const lightboxNext = lightbox.querySelector('.next-btn');
    const lightboxPrev = lightbox.querySelector('.prev-btn');

    let currentIndex = 0;
    let images = [];

    bindEventListeners();
    render();

    function bindEventListeners(){
        window.onresize = resizeCanvas

        window.addEventListener('keyup', event => {
            world.onKeyInput(event)
        }) 

        window.addEventListener('mousedown', function () {
            mDown = true;
        });
        
        window.addEventListener('mousemove', function () {
            if(mDown) {
                mDragging = true;
            }
        });

        const throttleMouseMove = throttle(world.onMouseMove, 100)
        window.addEventListener('mousemove', throttleMouseMove)

        if(isTouchDevice()){
            document.querySelector('html').classList.add('touchdevice');
            window.addEventListener('click', () => {
                world.onClick()
            })
        } else {
            window.addEventListener('mouseup', function() {
                // If not dragging, then it's a click!
                if(mDragging === false) {
                    world.onClick()
                }
            
                // Reset variables
                mDown = false;
                mDragging = false;

                const tutorial = document.querySelector('.tutorial')
                tutorial.classList.remove('visible');
                clearInterval(interval)
            });
        }

        start.addEventListener('click', () => {
            fixedWrapper.classList.add('started');
            // nav.classList.add('visible');
            unit.classList.add('visible');
            legend.classList.add('visible');
            world.init();
        })

        world.onInitialized(() => {
            console.log('World instance has been initialized!');

            setTimeout(() => {
                const tutorial = document.querySelector('.tutorial')
                tutorial.classList.add('visible');

                let currentIndex = 0;
                const totalSlides = document.querySelectorAll('.step').length;
                const steps = document.querySelectorAll('.step');

                function nextSlide() {
                    currentIndex = (currentIndex + 1) % totalSlides;
                    updateSlider();
                }

                function updateSlider() {
                    steps.forEach(x => x.classList.remove('active'));
                    steps[currentIndex].classList.add('active');
                }

                // Automatically advance to the next slide every 3 seconds (adjust as needed)
                interval = setInterval(nextSlide, 5000);

            }, 1000)
        });

        close.addEventListener('click', () => {
            world.resetAllMaterials();
            zoneInfo.classList.remove('visible');
            button.classList.remove('visible');
            wrapperDescription.classList.remove('visible');
            nav.querySelectorAll('span').forEach(x => x.classList.remove('active'));
            close.classList.remove('visible');
            if (wrapperDescription.querySelector('div.button-photo')) {
                wrapperDescription.querySelector('div.button-photo').remove();    
            }
            unit.classList.add('visible');

            description.innerHTML = '';
            world.resetPosition();
        })

        button.addEventListener('click', () => {
            button.classList.remove('visible');
            wrapperDescription.classList.add('visible'); 
            
            nav.classList.remove('visible');
            close.classList.remove('visible');
            
            closeDesc.classList.add('visible');
            info360.classList.add('visible');
            returnButton.classList.add('visible');

            unit.classList.remove('visible');

            groupButton.classList.add('visible');

            legend.classList.remove('visible');
            world.changeScene();
        })

        next.addEventListener('click', () => {
            world.switchScene(+1)
        })

        prev.addEventListener('click', () => {
            world.switchScene(-1)
        })

        returnButton.addEventListener('click', () => { 
            if (wrapperDescription.querySelector('div.button-photo')) {
                wrapperDescription.querySelector('div.button-photo').remove();    
            }
            description.innerHTML = '';
            
            zoneInfo.classList.remove('visible');
            button.classList.remove('visible');

            groupButton.classList.remove('visible');

            legend.classList.add('visible');
            closeDesc.classList.remove('visible');
            unit.classList.add('visible');
            nav.classList.add('visible');
            wrapperDescription.classList.remove('visible');
            if (wrapperDescription.querySelector('div.button-photo')) {
                wrapperDescription.querySelector('div.button-photo').remove();    
            }
            
            info360.classList.remove('visible');
            returnButton.classList.remove('visible');
            
            description.innerHTML = '';
            world.resetPosition();
        })
        
        closeDesc.addEventListener('click', () => {
            wrapperDescription.classList.remove('visible');
            closeDesc.classList.remove('visible');
        })

        infoButton.addEventListener('click', () => {
            wrapperDescription.classList.add('visible');
            closeDesc.classList.add('visible');
        });


        lightboxClose.addEventListener('click', closeLightbox);

        lightboxPrev.addEventListener('click', () => changeImage(-1));
        lightboxNext.addEventListener('click', () => changeImage(1));
    }

    world.addEventListener('focus', (e) => {
        button.classList.remove('visible');
        if (wrapperDescription.querySelector('div.button-photo')) {
            wrapperDescription.querySelector('div.button-photo').remove();    
        }
        description.innerHTML = '';


        let toHightlight = nav.querySelector(`span[data-label="${e.detail.name}"]`);
        if (toHightlight) {
            nav.querySelectorAll('span').forEach(x => x.classList.remove('active'));
            toHightlight.classList.add('active');
        }

        let hotspot = e.detail.hotspots;

        if(hotspot.titre){
            title.textContent = hotspot.titre;
        }
        
        hotspot?.visitable ? button.classList.add('visible') : wrapperDescription.classList.add('visible'); 
        if(hotspot?.description) {
                description.innerHTML = hotspot.description;    
        }

        if(hotspot?.caracteristiques) {
            const table = document.createElement('div');
            table.classList.add('table');

            const h4 = document.createElement('h4');
            h4.innerText = 'Caractéristiques techniques';
            table.appendChild(h4);

            for (let [key, value] of Object.entries(hotspot.caracteristiques)) {
                const row = document.createElement('div');
                row.classList.add('row');

                const span = document.createElement('span');
                span.innerText = key;

                const p = document.createElement('p');
                p.innerText = value;

                row.appendChild(span);
                row.appendChild(p);

                table.appendChild(row);
            }

            description.appendChild(table);
        }

        if(hotspot?.lien) {
            const button = document.createElement('a');
            button.href = hotspot.lien;
            button.setAttribute('target', "_blank");
            button.innerHTML = 'En savoir plus <svg class="svg-inline--fa fa-arrow-right-long" aria-hidden="true" focusable="false" data-prefix="fasr" data-icon="arrow-right-long" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M495 273l17-17-17-17L359 103l-17-17L308.1 120l17 17 95 95L24 232 0 232l0 48 24 0 396.1 0-95 95-17 17 33.9 33.9 17-17L495 273z"></path></svg>';
            description.appendChild(button);
        }
        
        if(hotspot?.photos?.length > 0) {
            const div = document.createElement('div');
            div.classList.add('button-photo');
            
            div.style.backgroundImage = `url(${importedImage[hotspot.photos[0].url]})`

            const span = document.createElement('span');
            span.innerHTML = 'Voir les photos <svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Group"><circle id="Oval" fill="#78BE20" cx="20" cy="20" r="20"></circle><path d="M24.3540206,19.3823672 C24.6934314,19.721778 24.6934314,20.2706124 24.3540206,20.6064125 L18.5768157,26.3872281 C18.2374049,26.7266389 17.6885704,26.7266389 17.3527704,26.3872281 C17.0169703,26.0478173 17.0133596,25.4989829 17.3527704,25.1631828 L22.5161473,19.9998059 L17.3491596,14.8328183 C17.0097488,14.4934075 17.0097488,13.944573 17.3491596,13.608773 C17.6885704,13.272973 18.2374049,13.2693622 18.5732049,13.608773 L24.3540206,19.3823672 Z" id="Path" fill="#FFFFFF" fill-rule="nonzero"></path></g></g></svg>';

            div.appendChild(span);

            div.addEventListener('click', () => {
                images = hotspot.photos;
                openLightbox(importedImage[hotspot.photos[0].url], hotspot.photos[0]);
            })

            wrapperDescription.appendChild(div);
        }

        zoneInfo.classList.add('visible');
        close.classList.add('visible');
    })

    world.addEventListener('label', (e) => {
        const span = document.createElement('span');
        span.innerText = e.detail.name;
        span.setAttribute('data-label', e.detail.name);

        span.addEventListener('click', () => {
            span.classList.add('active');
            world.centerView(e.detail.mesh);
        })

        nav.appendChild(span)
    })

    function resizeCanvas() {
        const sizer = document.querySelector('.sizer-canvas')

        canvas.style.width = '100%';
        canvas.style.height = '100%';
        
        canvas.width  = canvas.offsetWidth;
        canvas.height = canvas.offsetHeight;
        world.onWindowResize();
    }

    function render(){
        requestAnimationFrame(render);
        world.update()
    }

    function isTouchDevice() {
    return (('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0));
    }

    function throttle(func, wait, leading, trailing, context) {
        var ctx, args, result;
        var timeout = null;
        var previous = 0;
        var later = function() {
            previous = new Date;
            timeout = null;
            result = func.apply(ctx, args);
        };
        return function() {
            var now = new Date;
            if (!previous && !leading) previous = now;
            var remaining = wait - (now - previous);
            ctx = context || this;
            args = arguments;
            // Si la période d'attente est écoulée
            if (remaining <= 0) {
                // Réinitialiser les compteurs
                clearTimeout(timeout);
                timeout = null;
                // Enregistrer le moment du dernier appel
                previous = now;
                // Appeler la fonction
                result = func.apply(ctx, args);
            } else if (!timeout && trailing) {
                // Sinon on s’endort pendant le temps restant
                timeout = setTimeout(later, remaining);
            }
            return result;
        };
    };

    function openLightbox(imageSrc, ref) {
        var lightbox = document.getElementById('lightbox');
        var lightboxImage = document.getElementById('lightbox-image');
        
        var legend = ref?.legend;
        lightboxImage.src = imageSrc;
        lightbox.style.display = 'block';

        if(legend){
            var lightboxLegend = document.getElementById('legend');
            lightboxLegend.classList.add('visible');
            lightboxLegend.textContent = legend;
        } else {
            var lightboxLegend = document.getElementById('legend');
            lightboxLegend.classList.remove('visible');
            lightboxLegend.textContent = '';
        }

        currentIndex = 0
    }

    function closeLightbox() {
        var lightbox = document.getElementById('lightbox');
        lightbox.style.display = 'none';
        currentIndex = 0;
    }

    function changeImage(direction) {
        currentIndex += direction;
        if (currentIndex < 0) {
            currentIndex = images.length - 1;
        } else if (currentIndex >= images.length) {
            currentIndex = 0;
        }

        var newImageKey = images[currentIndex].url;
        var legend = images[currentIndex].legend;

        var lightboxImage = document.getElementById('lightbox-image');

        lightboxImage.src = importedImage[newImageKey];
        lightboxImage.alt = newImageKey;

        if(legend){
            var lightboxLegend = document.getElementById('legend');
            lightboxLegend.classList.add('visible');
            lightboxLegend.textContent = legend;
        } else {
            var lightboxLegend = document.getElementById('legend');
            lightboxLegend.classList.remove('visible');
            lightboxLegend.textContent = '';
        }
    }


} else {
    support.classList.add('visible');
}

