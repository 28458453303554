export class EventHandler {
    constructor() {
        this._eventTarget = new EventTarget();
    }

    addEventListener(type, listener) {
        this._eventTarget.addEventListener(type, listener);
    }

    removeEventListener(type, listener) {
        this._eventTarget.removeEventListener(type, listener);
    }

    dispatchEvent(event) {
        this._eventTarget.dispatchEvent(event);
    }
}