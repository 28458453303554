import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
// import * as dat from 'dat.gui'
import { Vector3 } from 'three'
import Bender from '../utils/bender'
// import { World } from '../internal'

export class Scene360 {
    constructor(sceneName, path, renderer, canvas) {
        this.lieux = document.querySelectorAll('.popup-lieux ul li');
        this.imgs = document.querySelectorAll('div.popup-lieux div.wrapper-lieux div.left-image img')
        this.title = document.querySelector('#box-title'); 

        this.renderer = renderer
        this.canvas = canvas
        this.currentIntersect = null
        
        this.sceneThat = this
        
        this.child = null

        this.hotspots = []
        this.points = []
        
        this.labels = []
        this.divs = []

        this.clock = new THREE.Clock()
        this.tempV = new THREE.Vector3();

        this.isMoving = false;
        /**
         * Debug 
         */
        // this.gui = new dat.GUI({name: 'Scène 360'});
        // this.gui.hide()

        // this.gui2 = new dat.GUI({name: 'Points Info'});
        // this.gui2.hide()

        this.sizes = {
            width: this.renderer.domElement.offsetWidth,
            height: this.renderer.domElement.offsetHeight
        };

        /**
         * Setup Scene
         */
        this.scene = new THREE.Scene();
        this.scene.name = sceneName
        this.scene.background = new THREE.Color(0,0,0)
        // this.scene.background = this.texture;

        /**
         * Sphere 360
         */
        const geometry = new THREE.SphereGeometry( 600, 60, 40 );
        const geometry2 = new THREE.SphereGeometry( 500, 60, 40 );
        geometry.scale( - 1, 1, 1 );
        geometry2.scale( - 1, 1, 1 );
        
        let alphaTexture, material, materialAlpha, sphere, sphereAlpha
        const texture = new THREE.TextureLoader().load(path);
        texture.magFilter = THREE.LinearFilter;
        texture.minFilter = THREE.LinearMipMapLinearFilter;
        texture.anisotropy = renderer.capabilities.getMaxAnisotropy();
        texture.colorSpace = THREE.SRGBColorSpace;

        material = new THREE.MeshBasicMaterial({ map: texture });
        material.toneMapped = false
        sphere = new THREE.Mesh( geometry, material );
        this.scene.add( sphere );
    

        /**
         * Setup Camera
         */
        this.camera = new THREE.PerspectiveCamera(80, this.sizes.width / this.sizes.height, 0.1, 10000);
        this.camera.position.set(0,1,-5)
        this.camera.lookAt(0,1,0)
        this.camera.updateProjectionMatrix();
        this.scene.add(this.camera)

        /**
         * Setup Raycaster
         */
        this.raycaster = new THREE.Raycaster()
        this.mouse = new THREE.Vector2()

        this.raycasterLabel = new THREE.Raycaster()

        /**
         * Setup Controls
         */
        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.controls.enabled = false;
        this.controls.enableZoom = false;
        this.controls.enableDamping = true;
        this.controls.enableRotate = true;
        this.controls.rotateSpeed = -0.25;
        this.controls.target.set(0,1,0);

        this.autorotateTimeout

        this.controls.addEventListener('start', () => {
            clearTimeout(this.autorotateTimeout);
            this.controls.autoRotate = false;
        });
        
        // restart autorotate after the last interaction & an idle time has passed
        this.controls.addEventListener('end', () => {
            this.autorotateTimeout = setTimeout(() => {
                this.controls.autoRotate = true;
            }, 2500);
        });

        this.scene = this.scene;
        this.camera = this.camera;

        this.ctaGlobalInfo = document.querySelector('button#info');
        this.allPopupInfo = document.querySelectorAll('.popup');
    }

    slugify(text){
        this.slug = text
        this.slug = this.slug.replace(/^\s+|\s+$/g, '');
        this.slug = this.slug.toLowerCase();
        var from = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
        var to   = "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";
        for (var i=0, l=from.length ; i<l ; i++) {
            this.slug = this.slug.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
        }
        this.slug = this.slug.replace(/[^a-z0-9 -]/g, '')
        .replace(/\s+/g, '-') 
        .replace(/-+/g, '-'); 

        return this.slug
    }

    clamp(num, min, max){
        let clamp = Math.min(Math.max(num, min), max);
        return clamp;
    } 

    show(){
        const time = this.clock.getElapsedTime()
        if(this.hotspots){
            for(let i=0; i<this.hotspots.length;i++){
                // this.hotspots[i].position.y += Math.cos( time ) * 0.01;

                this.hotspots[i].updateWorldMatrix(true, false);
                this.hotspots[i].getWorldPosition(this.tempV);

                this.tempV.project(this.camera)

                this.raycasterLabel.setFromCamera(this.tempV, this.camera)
                const intersectedObjects = this.raycasterLabel.intersectObjects(this.scene.children)

                const show = intersectedObjects.length && this.hotspots[i] === intersectedObjects[0].object;
                if(show || Math.abs(this.tempV.z) < 1){
                    const x = (this.tempV.x *  .5 + .5) * this.canvas.clientWidth;
                    const y = (this.tempV.y * -.5 + .5) * this.canvas.clientHeight;
                    
                    this.labels[i].style.display = ''

                    if(x > this.canvas.clientWidth){
                        this.labels[i].classList.add('outer-right');
                    } else {
                        this.labels[i].classList.remove('outer-right');
                    }

                    if(x < 0){
                        this.labels[i].classList.add('outer-left');
                    } else {
                        this.labels[i].classList.remove('outer-left');
                    }

                    if(y > this.canvas.clientHeight){
                        this.labels[i].classList.add('outer-bottom');
                    } else {
                        this.labels[i].classList.remove('outer-bottom');
                    }

                    if(y < 0){
                        this.labels[i].classList.add('outer-top');
                    } else {
                        this.labels[i].classList.remove('outer-top');
                    }

                    const axe_x = this.clamp(x, 0, this.canvas.clientWidth)
                    const axe_y = this.clamp(y, 0, this.canvas.clientHeight)
    
                    this.labels[i].style.transform = `translate3d(-50%, -50%, 0) translate3d(${axe_x}px, ${axe_y}px, 0)`
                    this.labels[i].classList.remove('outofbound')
                } else {
                    this.labels[i].classList.add('outofbound')
                }
                // this.labels[i].style.zIndex = (-this.tempV.z * .5 + .5) * 100000 | 0;
            }
        }
        
        if(this.points){
            for(let i=0; i<this.points.length; i++){
                this.points[i].updateWorldMatrix(true, false);
                this.points[i].getWorldPosition(this.tempV);

                this.tempV.project(this.camera)

                this.raycasterLabel.setFromCamera(this.tempV, this.camera)
                const intersectedObjects = this.raycasterLabel.intersectObjects(this.scene.children)

                const show = intersectedObjects.length && this.points[i] === intersectedObjects[0].object;
                if(!show || Math.abs(this.tempV.z) > 1){
                    this.divs[i].style.display = 'none'
                } else {
                    this.divs[i].style.display = ''

                    const x = (this.tempV.x *  .5 + .5) * this.canvas.clientWidth;
                    const y = (this.tempV.y * -.5 + .5) * this.canvas.clientHeight;
                
                    this.divs[i].style.transform = `translate3d(-50%, -50%, 0) translate3d(${x}px, ${y}px, 0)`
                    this.divs[i].style.zIndex = (-this.tempV.z * .5 + .5) * 100000 | 0;
                }
            }
        }
        this.controls.update()
        this.renderer.render(this.scene, this.camera);   
    }     

    enableScene(){
        this.controls.enabled = true;
        this.controls.autoRotate = true
        this.controls.autoRotateSpeed = 1
        
        // this.gui.show();
        // this.gui2.show();

        if(this.child){
            this.child = null
        }

        this.labels.forEach(el => {
            el.classList.add('visible')
        })

        this.divs.forEach(el => {
            el.classList.add('visible')
        })
    }

    disableScene(){
        this.controls.enabled = false
        this.controls.autoRotate = false
        // this.gui.hide()
        // this.gui2.hide()
        clearTimeout(this.autorotateTimeout);
        this.labels.forEach(el => {
            el.classList.remove('visible')
        })

        this.divs.forEach(el => {
            el.classList.remove('visible')
        })
    }
}