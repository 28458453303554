import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/addons/loaders/DRACOLoader.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { FontLoader } from 'three/addons/loaders/FontLoader.js';
import { EventHandler } from './EventHandler';
import hotspots from "../../static/text/text.json";

import gsap from 'gsap';

console.log(THREE.REVISION);

// HDR
import hdr from '../../static/textures/aristea_wreck_puresky_4k.hdr';

// CLASSE SCENE
import { Scene360 } from './Scene360';
import { render } from 'sass';

// MODELS
const filesContextGLTF = require.context('../../static/models', false, /\.(gltf)$/);

// IMAGE 360 
const filesContext = require.context('../../static/360', false, /\.(jpg)$/);
const imported360 = {};
filesContext.keys().forEach((key) => {
    const fileName = key.replace('./', '').replace('.jpg', '');
    imported360[fileName] = filesContext(key);
});

const divLoader = document.querySelector('div#loader');
const fixedWrapper = document.querySelector('.fixed-wrapper');

const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

const loadingBarElement = divLoader.querySelector('div.loading-bar');
THREE.DefaultLoadingManager.onStart = function ( url, itemsLoaded, itemsTotal ) {};

const percent = divLoader.querySelector('span#percent');
THREE.DefaultLoadingManager.onProgress = function ( url, itemsLoaded, itemsTotal ) {
    const progressRatio = itemsLoaded / itemsTotal;
    loadingBarElement.style.width = (Math.round(progressRatio * 100) / 100) * 100 + '%';
    percent.innerHTML = (Math.round(progressRatio * 100) / 100) * 100
};

THREE.DefaultLoadingManager.onError = function (url) {
    console.log('There was an error loading ' + url);
};

THREE.DefaultLoadingManager.onLoad = () => {
    // pmremGenerator.dispose();
    divLoader.classList.add('loaded');
    loadingBarElement.classList.add('ended')
}; 

var scene, camera, controls, renderer, raycaster, mouse, clock, previousTime, mixer, animations
var animCamera
var mainScene, current360, currentCanvas, currentObject, currentIndex

var children = [];
var actions = [];
var labels = [];
var scenes = [];

var texture_off, texture_on;

var wireframed = false;

var activeCamera = 0
let intersectedObject;

let clicked = false;

var initializedCallbacks = [];

export class World {
    constructor(canvas){
        /**
         * Scenes
         */
        currentCanvas = canvas;
        mainScene = new THREE.Scene();
        scene = mainScene;

        this._eventHandler = new EventHandler();

        /**
         * LOADER
         */
        new RGBELoader()
        .load(hdr, function ( texture ) {
            let pmremGenerator = new THREE.PMREMGenerator(renderer);
            let envMap = pmremGenerator.fromEquirectangular(texture).texture;
            pmremGenerator.compileEquirectangularShader();

            // scene.background = new THREE.Color( 0x000000 );
            scene.environment = envMap;

            texture.dispose();
            pmremGenerator.dispose();
        } );


        /**
         * Renderer
         */
        renderer = new THREE.WebGLRenderer({ 
            canvas: canvas,
            antialias : true, 
            alpha: true,
            powerPreference: 'high-performance'
        })

        renderer.setSize(sizes.width, sizes.height)
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
        
        renderer.toneMapping = THREE.ACESFilmicToneMapping;
        renderer.toneMappingExposure = 1;
        renderer.outputColorSpace = THREE.SRGBColorSpace;

        /**
         * Models 
         */
        this.loadModels();

        /**
         * Raycaster
         */
        raycaster = new THREE.Raycaster()

        /**
         * Animate
         */
        clock = new THREE.Clock()
        previousTime = 0

        this.scaleUp = function(i){
            gsap.to(i.scale, {
                duration: 0.5,
                x: 0.223,
                y: 0.223,
                z: 0.223,
                ease: "bounce.out",
            })
        }

        this.scaleDown = function(i){
            gsap.to(i.scale, {
                duration: 0.5,
                x: 0.153,
                y: 0.153,
                z: 0.153,
                ease: "bounce.out",
            })
        }
    }

    addEventListener(type, listener) {
        this._eventHandler.addEventListener(type, listener);
    }

    removeEventListener(type, listener) {
        this._eventHandler.removeEventListener(type, listener);
    }

    async loadModels(){
        const gltfLoader = new GLTFLoader();
        const dracoLoader = new DRACOLoader();

        dracoLoader.setDecoderPath('/draco/')
        dracoLoader.setDecoderConfig({ type: 'js' });
        gltfLoader.setDRACOLoader(dracoLoader)    
        
        const modelFiles = filesContextGLTF.keys().map((key) => key.replace('./', '').replace('.gltf', ''));        
        console.log(modelFiles);
        const modelPromises = modelFiles.map((modelName) =>
            gltfLoader.loadAsync(`assets/models/${modelName}.gltf`)
        );
        
        const modelDataArray = await Promise.all(modelPromises);
        modelDataArray.forEach(model => {
            if(model.cameras[0]){
                animCamera = model.cameras[0];
                animCamera.matrixAutoUpdate = true
                animCamera.parent.matrixAutoUpdate = true
            }

            if(model.animations.length > 0){
                mixer = new THREE.AnimationMixer(model.scene);

                animations = model.animations;
                if(animations.length > 0){
                    animations.forEach((a, index) => {
                        actions.push(mixer.clipAction(a))
                    })
                    this.updateAllActions()
                }
            }

            if(model.scene.children){
                children.push(model.scene.children[0])
                if (hotspots[model.scene.children[0].name]) {
                    let hotspot = hotspots[model.scene.children[0].name];
                    if (hotspot.visitable && hotspot[360].length > 0) {
                        for (let image of hotspot[360]) {
                            let equirect = imported360[image].default
                            let scene = new Scene360(hotspot.titre, equirect, renderer, currentCanvas);
                            if (model.scene.children[0].userData.scene360) {
                                model.scene.children[0].userData.scene360.push(scene);        
                            } else {
                                model.scene.children[0].userData.scene360 = [];
                                model.scene.children[0].userData.scene360.push(scene);    
                            }
                        }
                    }
                    model.scene.children[0].userData.hotspots = hotspot;
                }

                if(model.scene.children[0].name !== "Terrain" && model.scene.children[0].name !== "Camera"){
                    this.createLabel(model.scene.children[0].userData.name, model.scene.children[0]);
                }

                mainScene.add(model.scene.children[0]); 
                mainScene.updateMatrixWorld(true);
            }

            this.onWindowResize();
        });
    }

    updateCamera() {
        controls.target.set(12.65,104.62,-2290);
    }

    onKeyInput = function(e){
        if(e.code === "KeyZ" && !wireframed){
            children.forEach((child) => {
                if(child.material){
                    child.material.wireframe = true;
                }                        
                mainScene.updateMatrixWorld(true);
            })
            wireframed = true;
        } else {
            children.forEach((child) => {
                if(child.material){
                    child.material.wireframe = false;
                }                        
                mainScene.updateMatrixWorld(true);
            })
            wireframed = false;
        }

        if(e.code === 'KeyO'){
            console.log(camera.position);
        }
    }

    onMouseMove = (event) => {
        mouse = new THREE.Vector2()
        const rect = renderer.domElement.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        mouse.x = (x / sizes.width) * 2 - 1
        mouse.y = - (y / sizes.height) * 2 + 1

        if(camera && !clicked){
            raycaster.setFromCamera(mouse, camera)
            let intersects = raycaster.intersectObjects(scene.children, true)
            if(intersects.length > 0){
                if (intersects.length > 0) {
                    // If there's an intersection, change the color and store the intersected object
                    if (intersectedObject !== intersects[0].object) {
                        if (intersectedObject) {
                            // Reset the color of the previous intersected object
                            this.resetMaterials();
                        }
                
                        intersectedObject = intersects[0].object.type === 'Sprite' ? intersects[0].object.parent : intersects[0].object;
                        if(intersectedObject.name !== 'Terrain'){
                            // Change the color of the current intersected object
                            this.setMaterials()
                        } else {
                            this.resetMaterials();
                        }
                    }
                } else {
                    // No intersection, reset the color and clear the stored intersected object
                    if (intersectedObject) {
                        this.resetMaterials();
                        intersectedObject = undefined;
                    }
                }
            } 
        }
    }

    setMaterials(){
        // intersectedObject.material.color.set(0xFF236192);
        if (intersectedObject.children.length > 0) {
            let label = intersectedObject.children[0]
            label.material = intersectedObject.userData.texture_on;
        }
        document.body.style.cursor = 'pointer';
    }

    resetMaterials(){
        // intersectedObject.material.color.set(0xFFFFFF);
        if (intersectedObject.children.length > 0) {
            let label = intersectedObject.children[0]
            label.material = intersectedObject.userData.texture_off;
        }
        document.body.style.cursor = 'default';
    }

    resetAllMaterials() {
        for (let model of children) {
            if (model) {
                if (model.material) {
                    model.material.color.set(0xFFFFFF);
                    if (model.children.length > 0) {
                        let label = model.children[0]
                        label.material = model.userData.texture_off;
                    }        
                }
            }
        }
    }

    onClick = function(){
        if(camera){
            raycaster.setFromCamera(mouse, camera)
            let intersects = raycaster.intersectObjects(scene.children, true)
            if(intersects.length > 0){
                if(intersects[0].object.name !== "Terrain" && !clicked){
                    let currentIntersect = intersects[0].object.type === "Sprite" ? intersects[0].object.parent : intersects[0].object
                    let target_2 = new THREE.Vector3();

                    let boundingBox = currentIntersect.geometry.boundingBox;
                    target_2.subVectors(boundingBox.max, boundingBox.min);
                    target_2.multiplyScalar(0.5);
                    target_2.add(boundingBox.min);
                    target_2.applyMatrix4(currentIntersect.matrixWorld);

                    let tl = gsap.timeline();
                    tl.add('start')
                    .to(controls.target, {
                        duration: 1,
                        ease: 'back.out(1)',
                        x: target_2.x,
                        y: 2,
                        z: target_2.z,
                    }, 'start')
                    .to(controls, {
                        duration: 1,
                        ease: 'back.out(1)',
                        maxDistance: 3300,
                        onComplete: () => {
                            if(!clicked){
                                controls.autoRotate = true;
                                controls.enabled = false;
                                clicked = true;
                                this._triggerFocusEvent(currentIntersect.userData.name, currentIntersect.userData.hotspots);
                                currentIntersect.material.color.set(0xFF236192);
                                currentObject = currentIntersect;
                                currentIndex = 0;
                            }
                        }
                    }, 'start');
                    
                    if(currentIntersect.children.length > 0) {
                        this.hideLabel()    
                    }
                }
            }
        }
    }

    centerView(mesh) {
        this.resetAllMaterials();
        clicked = false;

        let target_2 = new THREE.Vector3();
        let boundingBox = mesh.geometry.boundingBox;
        target_2.subVectors(boundingBox.max, boundingBox.min);
        target_2.multiplyScalar(0.5);
        target_2.add(boundingBox.min);
        target_2.applyMatrix4(mesh.matrixWorld);

        let tl = gsap.timeline();
        tl.add('start')
        .to(controls.target, {
            duration: 1,
            ease: 'back.out(1)',
            x: target_2.x,
            y: 2,
            z: target_2.z,
        }, 'start')
        .to(controls, {
            duration: 1,
            ease: 'back.out(1)',
            maxDistance: 3300,
            onComplete: () => {
                if(!clicked){
                    controls.autoRotate = true;
                    controls.enabled = false;
                    clicked = true;
                    this._triggerFocusEvent(mesh.userData.name, mesh.userData.hotspots);
                    mesh.material.color.set(0xFF236192);
                    currentObject = mesh;
                }
            }
        }, 'start');
        
        if(mesh.children.length > 0) {
            this.hideLabel()    
        }
    }

    resetPosition() {
        this.resetAllMaterials();
        if (current360) {
            current360.disableScene();  
            current360 = null;    
        }
        activeCamera = 1;

        let tl = gsap.timeline();
        tl.add('start')
        .to(camera.position, {
            x: -4522.460937499999,
            y: 1879.8751220703145,
            z: -7862.048828125,
            duration: 1,
            ease: 'back.out(1)',
        }, 'start')
        .to(controls.target, {
            x: 1605.173,
            y: 288.249,
            z:  -3190.433,
            duration: 1,
            ease: 'back.out(1)',
        }, 'start')
        .to(controls, {
            duration: 1,
            ease: 'back.out(1)',
            maxDistance: 7900,
            onComplete: () => {
                clicked = false
                controls.autoRotate = false;
                controls.enabled = true;
                currentObject = null;
            }
        }, 'start');

        this.showLabel();
    }

    onWindowResize = function(){
        // Update sizes
        sizes.width = window.innerWidth
        sizes.height = window.innerHeight

        // Update camera
        if(camera){
            camera.aspect = sizes.width / sizes.height
            camera.updateProjectionMatrix()
        }

        if (animCamera) {
            animCamera.aspect = sizes.width / sizes.height
            animCamera.updateProjectionMatrix()
        }
        
        // Update renderer
        renderer.setSize(sizes.width, sizes.height)
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    }

    clamp(num, min, max){
        let clamp = Math.min(Math.max(num, min), max);
        return clamp;
    }

    closest(array, number) {
        var num = 0;
        for (var i = array.length - 1; i >= 0; i--) {
            if(Math.abs(number - array[i].position) < Math.abs(number - array[num].position)){
                num = i;
            }
        }
        return array[num].element;
    }   

    update(){
        const elapsedTime = clock.getElapsedTime();
        const deltaTime = elapsedTime - previousTime;
        previousTime = elapsedTime;

        // Update controls
        if(controls){
            controls.update()
        }

        // Update Mixer
        if(mixer){
            mixer.update(deltaTime)
        }
        
        // Render
        if(activeCamera === 0 && animCamera){
            renderer.render(scene, animCamera)
        } 
        
        if(activeCamera === 1){
            renderer.render(scene, camera)
        }        
        
        if(activeCamera !== 0 && activeCamera !== 1){
            current360.show()
            if(current360.child){
                current360.disableScene()
                current360 = current360.child
                current360.enableScene()
            }
        }
        
        if(children.length > 0){
            for(let model of children){
                let labelSprite = model.children[0];
                if(labelSprite){
                    // let position = this.getWorldPosition(model);
                    // labelSprite.position.copy(position);
                    if(camera){
                        labelSprite.lookAt(camera.position);
                    }
                }
                
            }
        }
    }
    
    updateAllActions(){
        if(actions.length > 0){
            actions.forEach(action => {
                action.clampWhenFinished = true;
                action.loop = THREE.LoopOnce;
            })
        }
    }

    playAllActions(){
        if(actions.length > 0){
            actions.forEach(action => {
                action.play()
            })
        } else {
            console.log('Aucune animations');
        }

    }

    restartAllActions(){
        if(actions.length > 0){
            actions.forEach(action => {
                action.reset()
            })    
        }
    }

    onInitialized(callback) {
        // Check if the callback array is not defined, create it
        if (!initializedCallbacks) {
            initializedCallbacks = [];
        }
    
        // Add the callback to the array
        initializedCallbacks.push(callback);
    }

    init(){
        this.playAllActions();
        let that = this;
        if(mixer){
            mixer.addEventListener("finished", function(e){
                camera = animCamera.clone();
                scene.add(camera);

                controls = new OrbitControls(camera, renderer.domElement);
                controls.enableDamping = true;
                controls.enablePan = true;
                controls.autoRotate = true;
                camera.matrixAutoUpdate = true

                let autorotateTimeout
                controls.addEventListener('start', () => {
                    clearTimeout(autorotateTimeout);
                    controls.autoRotate = false;
                });
                
                // restart autorotate after the last interaction & an idle time has passed
                controls.addEventListener('end', () => {
                    autorotateTimeout = setTimeout(() => {
                        controls.autoRotate = true;
                    }, 2500);
                });

                // ORIGINAL CAMERA POSITION x: -4522.460937499999, y: 1879.8751220703145, z: -7862.048828125
                
                // MAX VERS HAUT
                controls.minPolarAngle = (Math.PI / 2.50)
                
                // MAX VERS BAS
                controls.maxPolarAngle = (Math.PI / 2.17)

                //ZOOM AVANT
                controls.minDistance = 3100
                
                //ZOOM ARRIERE
                controls.maxDistance = 7900

                controls.target.set(1605.173, 288.249, -3190.433);
                // ORIGINAL TARGET POSITION x: 1605.173, y: 288.249, z: -3190.433

                activeCamera = 1
                that.showLabel();

                initializedCallbacks.forEach(callback => callback());
            })
        }
    }

    _triggerFocusEvent(name, hotspots){
        const onFocus = new CustomEvent('focus', { detail: { name: name, hotspots: hotspots } });
        this._eventHandler.dispatchEvent(onFocus);
    }

    _triggerLabelEvent(name, mesh) {
        const onLabel = new CustomEvent('label', { detail: { name: name, mesh: mesh } });
        this._eventHandler.dispatchEvent(onLabel);
    }

    getWorldPosition(mesh){
        mesh.geometry.computeBoundingBox();

        var boundingBox = mesh.geometry.boundingBox;

        var position = new THREE.Vector3();
        position.subVectors( boundingBox.max, boundingBox.min );
        position.multiplyScalar( 0.5 );
        position.add( boundingBox.min );

        position.applyMatrix4( mesh.matrixWorld );
        return new THREE.Vector3(position.x, position.y, position.z);
    }

    createLabel(label, mesh) {
        let visitable = mesh.userData?.hotspots ? mesh.userData.hotspots.visitable : false;

        this.createTextureOn(label, mesh, visitable);
        let text = this.createTextureOff(label, mesh, visitable);

        const labelSprite = new THREE.Sprite(texture_off);
        labelSprite.name = "Sprite - " + label;
        
        mesh.add(labelSprite);
        // labelSprite.scale.set(0,0,1);

        let position = this.getWorldPosition(mesh)
        labelSprite.position.copy(position);
        labelSprite.position.z += -350

        labels.push({ label: labelSprite, initialSize: { x: text.width + 60, y: text.hangingBaseline + 80 } });
        
        this._triggerLabelEvent(label, mesh);
    }

    showLabel() {
        let tl = gsap.timeline();
        for(let label of labels){
            tl.to(label.label.scale, { x: label.initialSize.x, y: label.initialSize.y }, { duration: 0.2, ease: 'power4.out' })
            tl.to({},{duration: 0.6})
        }
    }

    createTextureOff(label, mesh, visitable) {
        const labelCanvas = document.createElement('canvas');
        const labelContext = labelCanvas.getContext('2d');
        labelContext.font = '60px Arial';

        // Measure text dimensions
        let text = labelContext.measureText(label);
        labelCanvas.width = text.width + 60;
        labelCanvas.height = text.hangingBaseline + 80;

        labelContext.globalCompositeOperation = 'destination-over';
        
        // Draw the rounded rectangle
        labelContext.rect(0, 0, text.width + 60, text.hangingBaseline + 40);
        labelContext.fillStyle = visitable ? '#78BE20' : '#A7A8AA';
        labelContext.fill();

        // Draw the triangle

        if (visitable) {
            labelContext.beginPath();
            labelContext.moveTo((labelCanvas.width / 2), (text.hangingBaseline + 40)); // Milieu Haut
            labelContext.lineTo((labelCanvas.width / 2) - 40, (text.hangingBaseline + 40)); // Gauche Haut
            labelContext.lineTo((labelCanvas.width / 2), (text.hangingBaseline + 40) + 40); // Milieu Bas
            labelContext.lineTo((labelCanvas.width / 2) + 40, (text.hangingBaseline + 40)); // Droit Haut
            labelContext.closePath();
            labelContext.lineWidth = 1;
            labelContext.strokeStyle = visitable ? '#78BE20' : '#A7A8AA';
            labelContext.stroke();

            labelContext.fillStyle = visitable ? '#78BE20' : '#A7A8AA';
            labelContext.fill();
        }
        


        labelContext.globalCompositeOperation = 'source-over';
        
        // Draw the text at the center
        labelContext.font = 'bold 45px Arial';
        let text_2 = labelContext.measureText(label);

        labelContext.fillStyle = '#FFFFFF';
        labelContext.strokeStyle = '#FFFFFF';
        let centerX = (labelCanvas.width - (text_2.width)) / 2;
        let centerY = 54
        labelContext.fillText(label, centerX, centerY);
        
        const labelTexture = new THREE.CanvasTexture(labelCanvas);
        
        labelTexture.magFilter = THREE.NearestFilter;
        labelTexture.minFilter = THREE.LinearMipMapLinearFilter;
        labelTexture.colorSpace = THREE.SRGBColorSpace

        const labelMaterial = new THREE.SpriteMaterial({ map: labelTexture });
        labelMaterial.toneMapped = false;
        texture_off = labelMaterial;

        mesh.userData.texture_off = texture_off;

        return text;
    }

    createTextureOn(label, mesh, visitable) {
        // TEXTURE SURVOL
        const labelCanvasOn = document.createElement('canvas');
        const labelContextOn = labelCanvasOn.getContext('2d');
        labelContextOn.font = '60px Arial';

        // Measure textOn dimensions
        let textOn = labelContextOn.measureText(label);
        labelCanvasOn.width = textOn.width + 60;
        labelCanvasOn.height = textOn.hangingBaseline + 80;

        labelContextOn.globalCompositeOperation = 'destination-over';

        // Draw the rounded rectangle
        labelContextOn.rect(0, 0, textOn.width + 60, textOn.hangingBaseline + 40);
        labelContextOn.fillStyle = "#599b06";
        labelContextOn.fill();

        // Draw the triangle

        if (visitable) {
            labelContextOn.beginPath();
            labelContextOn.moveTo((labelCanvasOn.width / 2), (textOn.hangingBaseline + 40));
            labelContextOn.lineTo((labelCanvasOn.width / 2) - 40, (textOn.hangingBaseline + 40));
            labelContextOn.lineTo((labelCanvasOn.width / 2), (textOn.hangingBaseline + 40) + 40);
            labelContextOn.lineTo((labelCanvasOn.width / 2) + 40, (textOn.hangingBaseline + 40));
            labelContextOn.closePath();
            labelContextOn.lineWidth = 1;
            labelContextOn.strokeStyle = "#599b06";
            labelContextOn.stroke();

            labelContextOn.fillStyle = "#599b06";
            labelContextOn.fill();
        }


        labelContextOn.globalCompositeOperation = 'source-over';

        // Draw the textOn at the center
        labelContextOn.font = 'bold 45px Arial';
        let text_2On = labelContextOn.measureText(label);

        labelContextOn.fillStyle = '#FFFFFF';
        labelContextOn.strokeStyle = '#FFFFFF';
        let centerXOn = (labelCanvasOn.width - (text_2On.width)) / 2;
        let centerYOn = 54
        labelContextOn.fillText(label, centerXOn, centerYOn);

        const labelTextureOn = new THREE.CanvasTexture(labelCanvasOn);
        labelTextureOn.magFilter = THREE.NearestFilter;
        labelTextureOn.minFilter = THREE.LinearMipMapLinearFilter;
        labelTextureOn.colorSpace = THREE.SRGBColorSpace

        const labelMaterialOn = new THREE.SpriteMaterial({ map: labelTextureOn });
        labelMaterialOn.toneMapped = false;
        texture_on = labelMaterialOn;
        
        mesh.userData.texture_on = texture_on;
    }

    hideLabel() {
        let tl = gsap.timeline();
        for(let label of labels){
            tl.to(label.label.scale, { x: 0, y: 0 }, { duration: 0.2, ease: 'power4.out' })
            tl.to({},{duration: 0.6})
        }
    }

    changeScene(index = 0) {
        if(currentObject) {
            if (currentObject.userData.scene360.length > 0) {
                current360 = currentObject.userData.scene360[index]
                
                document.querySelector('span#total').textContent = currentObject.userData.scene360.length
                document.querySelector('span#current').textContent = 1
                activeCamera = 2;
                current360.enableScene();    
            }
        }
    }

    switchScene(direction) {
        currentIndex += direction;
        if(currentObject) {
            if (currentObject.userData.scene360.length > 0) {
                current360.disableScene()

                if (currentIndex < 0) {
                    currentIndex = currentObject.userData.scene360.length - 1;
                } else if (currentIndex >= currentObject.userData.scene360.length) {
                    currentIndex = 0;
                }
                
                document.querySelector('span#current').textContent = currentIndex + 1
                current360 = currentObject.userData.scene360[currentIndex]
                current360.enableScene();    
            }
        }
    }
}

